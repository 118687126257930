
<div class="card flex justify-content-center">
    <p-card styleClass="w-full h-25rem" [header]="header" [subheader]="subHeader">
        <ng-content></ng-content>
        <ng-template pTemplate="footer">
            <div class="flex justify-content-center">
                <a [href]="buttonAction" target="_blank" rel="noopener noreferrer" class="p-button font-bold no-underline">{{buttonLabel}}</a>
            </div>
        </ng-template>
    </p-card>
</div>