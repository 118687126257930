import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.scss'
})
export class AppFooterComponent {
  src_url= '../../../../../assets/terms-privacy.pdf'
  // src_url= 'https://files.saraplus.com/Documents/Terms_Conditions/Terms_of_Use_and_Privacy_Policy_Simple.pdf'
  contactUs: boolean = false;
  privacyTerms: boolean = false;

  showContactus() {
      this.contactUs = true;
  }

  closeContactus() {
      this.contactUs = false;
  }

  showPivacyTerms() {
    this.privacyTerms = true;
  }

}
