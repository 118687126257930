import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription, filter } from 'rxjs';
import { Event, RouterEvent, Router, NavigationEnd } from '@angular/router';
@Component({
  template: ''
})
export class BaseAppLayoutComponent implements OnDestroy {
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  protected subscriptions = new Subscription();
}

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent extends BaseAppLayoutComponent implements OnInit {

  @Input() logoUrl: string = '';
  @Input() menuItems: MenuItem[] | undefined;
  @Input() isLoggedIn: Observable<boolean> | undefined = undefined;

  private readonly router: Router = inject(Router);

  ngOnInit(): void {
    this.router.events.pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof NavigationEnd)
    ).subscribe((e: RouterEvent) => {
      console.log(e.url);
    });
  }
}
