import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
    template: ''
})
export class BaseLayoutComponent implements OnDestroy {
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    protected subscriptions = new Subscription();
}