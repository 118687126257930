import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BadgeModule } from 'primeng/badge';
import { DockModule } from 'primeng/dock';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DropdownModule,
    CardModule,
    CarouselModule,
    MenubarModule,
    ButtonModule,
    DividerModule,
    InputTextModule,
    RadioButtonModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    DialogModule,
    DragDropModule,
    InputSwitchModule,
    TieredMenuModule,
    BadgeModule,
    DockModule,
    MenuModule,
    SidebarModule,
    CheckboxModule,
    InputTextareaModule,
    TabViewModule,
    ToastModule,
    BreadcrumbModule,
    OverlayPanelModule,
    ScrollTopModule,
    PanelMenuModule,
    PasswordModule
  ],
  exports: [
    DropdownModule,
    CardModule,
    MenubarModule,
    ButtonModule,
    DividerModule,
    InputTextModule,
    RadioButtonModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    DialogModule,
    DragDropModule,
    InputSwitchModule,
    TieredMenuModule,
    BadgeModule,
    DockModule,
    MenuModule,
    SidebarModule,
    CheckboxModule,
    InputTextareaModule,
    TabViewModule,
    ToastModule,
    BreadcrumbModule,
    OverlayPanelModule,
    ScrollTopModule,
    PanelMenuModule,
    PasswordModule,
    CarouselModule
  ],
  providers: [MessageService],
  schemas: []
})
export class PrimeNGModule { }
