<div>
    <div class="saraplus-bg-logo">
        <div class="flex justify-content-end mr-4 sm:mr-8 mt-4 pr-3 lg:pr-8 text-center relative">
            <a href="https://saraplus.myspreadshop.com/" target="_blank" class="px-3 pt-2 text-center text-white">Store</a>
            <a href="https://www.saraplus.com/signup" target="_blank" class="px-3 pt-2 text-center text-white">Get Access!</a>
            <a href="https://www.saraplus.com/e/servicepages/login.aspx" class="mx-2 px-4 p-element p-ripple p-button p-component no-underline">
                <span class="p-button-label">Login</span>
            </a>
        </div>
        <div class="text-center px-6">
            <div class="mt-6 lg:mt-4 xl:mt-2">
                <img alt="Sara Plus" class="relative w-9 sm:w-7 md:w-5 lg:w-4 xl:w-3" src="assets/images/logo/SARAWhiteLogoBorderless.png">
            </div>       
        </div>
    </div>

    <div class="bg-blue-100 px-2 md:px-3 py-6">
        <div class="grid nogutter">
           
            <div class="col-12 sm:col-5 md:col-6 lg:col-7 pt-8">
                <div class="mb-3 font-bold text-3xl text-center pb-5">
                    <span class="text-900 text-blue-600">Multi-Provider Order Entry</span>
                </div>
                <div class="mb-3 text-2xl pl-3 text-center">
                    <span class="text-900 line-height-4 text-blue-600">A powerful yet easy-to-use tool that seamlessly consolidates orders from multiple service providers into an efficient, unified system. Featuring automated validation, real-time tracking, and customizable workflows, SARA Plus™ streamlines operations and enhances productivity across the board.
                    </span>
                </div>
            </div>
            <div class="flex justify-content-center align-items-center col-12 sm:col-7 md:col-6 lg:col-5 bg mt-6">
                    <div class="circle">
                        <img alt="Order Entry" src="assets/images/icon/order-entry.png">
                    </div>               
            </div>
        </div>
    </div>

    <div class="bg-blue-600 px-2 md:px-3 py-6">
        <div class="grid nogutter flex-column-reverse sm:flex-row">

            <div class="flex justify-content-center align-items-center col-12 sm:col-7 md:col-6 lg:col-5 bg mt-6">
                <div class="circle surface-ground">
                    <img alt="Reporting" src="assets/images/icon/reporting.png">
                </div>
            </div>
           
            <div class="col-12 sm:col-5 md:col-6 lg:col-7 pt-8">
                <div class="mb-3 font-bold text-3xl text-center pb-5">
                    <span class="text-900 text-white">Reporting</span>
                </div>
                <div class="mb-3 text-2xl pl-3 text-center">
                    <span class="text-900 line-height-4 text-white">SARA Plus™ delivers comprehensive insights and analytics on order data, giving users a thorough view of trends, performance metrics, and key indicators. The Reporting feature equips companies with the business intelligence needed to make data-driven decisions and optimize processes, increasing efficiency and effectiveness in order management.
                    </span>
                </div>
            </div>
           
        </div>
    </div>
    
    <div class="bg-blue-800 px-2 md:px-3 py-6">
        <div class="grid nogutter">
           
            <div class="col-12 sm:col-5 md:col-6 lg:col-7 pt-8">
                <div class="mb-3 font-bold text-3xl text-center pb-5">
                    <span class="text-900 text-white">Secure Data</span>
                </div>
                <div class="mb-3 text-2xl pl-3 text-center">
                    <span class="text-900 line-height-4 text-white">To protect data integrity and confidentiality, the SARA Plus™ Secure Data infrastructure actively mitigates risks. This provides peace of mind for businesses by safeguarding valuable information from unauthorized access or breaches.
                    </span>
                </div>
            </div>
            <div class="flex justify-content-center align-items-center col-12 sm:col-7 md:col-6 lg:col-5 bg mt-6">
                <div class="circle bg-blue-500">
                    <img alt="Secure Data" src="assets/images/icon/secure-data.png">
                </div>
            </div>
        </div>
    </div>



    <div id="vendors" class="saraplus-vendor-bg px-2 md:px-3 py-4">
        <div class="grid nogutter md:mb-6">
            <div class="col-12 bg lg:col-6 vendorStage my-4 sm:my-7">
                <img
                src="assets/images/vendors/SARA-Plus.png"
                class="vl saraVendor animate pop"
                alt="SARA Plus™ short logo"
              />
                <div class="vl2 shape animate pop"></div>
                <img
                src="https://www.saraplus.com/css/img/partner-logos/att.png"
                class="vl3 delay-1 attVendor animate pop"
                alt="AT&amp;T logo"
              />
                <img src="assets/images/vendors/directv.png" class="vl4 delay-2 directvVendor animate pop" alt="DIRECTV logo">
                <img src="assets/images/vendors/consolidated.png" class="vl5 delay-3 consolidatedVendor animate pop" alt="Consolidated Communications logo">
                <img src="assets/images/vendors/lumen.png" class="vl6 delay-4 lumenVendor animate pop" alt="Lumen logo">
                <img src="assets/images/vendors/hughesnet.png" class="vl7 delay-5 hughesnetVendor animate pop" alt="Hughesnet logo">
                <img src="assets/images/vendors/earthlink.png" class="vl8 delay-6 earthlinkVendor animate pop" alt="EarthLink logo">
                <img src="assets/images/vendors/viasat.png" class="vl9 delay-7 viasatVendor animate pop" alt="Viasat logo">
                <img src="assets/images/vendors/wow.png" class="vl10 delay-8 wowVendor animate pop" alt="WOW logo">
                <img src="assets/images/vendors/ziply.png" class="vl11 delay-9 ziplyVendor animate pop" alt="Ziply Fiber logo">
                <img src="assets/images/vendors/frontier.png" class="vl12 delay-10 frontierVendor animate pop" alt="Frontier Authorized Sales Agent logo">
                <img src="assets/images/vendors/brightspeed.png" class="vl13 delay-11 brightspeedVendor animate pop" alt="Brightspeed logo">
                <div class="col-12 text-center mt-6 lg:mt-8">
                    <span class="font-light text-color-secondary text-xs">2024 AT&T Intellectual Property. All Rights Reserved. AT&T, the AT&T logo, all other AT&T marks contained herein are trademarks of AT&T Intellectual Property and/or AT&T affiliated companies.</span>
                </div>
            </div>
            <div class="col-12 bg lg:col-6 mt-8 pt-6 sm:pt-2 px-5 lg:pt-5">
                <div class="mb-3 font-bold text-3xl text-center pb-5">
                    <span class="text-900 text-blue-600">Trusted Vendors</span>
                </div>
                <div class="mb-3 text-2xl text-center">
                    <span class="text-900 line-height-4 text-center text-blue-600">SARA Plus™ is the order entry tool of choice for an ever-growing list of trusted service providers, directly integrating with their systems to simplify and enhance the sales process.</span>
                </div>
            </div>
            
        </div>
    </div>

</div>