<div class="surface-section md:pt-4 grid sm:flex justify-content-center">
    <div class="sm:my-2">
        <span class="font-light text-color-secondary text-xs">© 2024 SARA Plus, LLC. All rights reserved.</span>    
    </div>
    <div class="sm:flex sm:my-2 justify-content-center">
        <div class="flex justify-content-center">
                &nbsp;<p-button styleClass="vertical-align-super px-0 underline font-light text-color-secondary text-xs" [style]="{'padding-top': '3px'}" [link]="true" (click)="showPivacyTerms()">Privacy Policy & Terms of Use</p-button>
                <span class="font-light vertical-align-super text-color-secondary">&nbsp;|&nbsp;</span>
                <p-button styleClass="vertical-align-super px-0 underline font-light text-color-secondary text-xs" [style]="{'padding-top': '3px'}" [link]="true" (click)="showContactus()">Contact Us Form</p-button>
        </div>
    </div>
    <div class="mb-3 sm:my-2">
        &nbsp;&nbsp;<span class="font-light text-color-secondary text-xs">Contact: info&#64;saraplus.com</span>
    </div>
</div>

<p-dialog header="Contact Us Form" styleClass="sm:w-9" [(visible)]="contactUs">
    <div class="grid nogutter saraplus-bg-corner">
        <div class="col-12 md:col-6">
            <div class="mb-3 font-bold text-3xl pb-5 mt-2">
                <span class="text-900">How can we help?</span>
            </div>
            <div class="mb-3 text-2xl">
                <span class="text-900 line-height-4">Have a question, comment or concern? Submit your query using the form and the SARA Plus™ support team will get back to you shortly.</span>
            </div>
        </div>
        <div class="border-round col-12 pt-0 md:col-6 bg-white">
            <div class="card sm:px-3">
                <div class="p-fluid p-formgrid grid mt-0">
                    <div class="col-12 pt-2 pb-0 ">
                        <span>Name<span class="text-red-500">*</span></span>
                    </div>
                    <div class="field col-12 pt-0 sm:col-6 mb-0">
                        <input pInputText id="firstname" type="text" class="p-inputtext-sm mb-0"/>
                        <small class="pl-1 font-light text-color-secondary text-xs" htmlFor="firstname">First Name</small>
                    </div>
                    <div class="field col-12 pt-0 sm:col-6 m-0">
                        <input pInputText id="lastname" type="text" class="p-inputtext-sm mb-0" />
                        <small class="pl-1 font-light text-color-secondary text-xs" htmlFor="lastname">Last Name</small>
                    </div>
                    <div class="field col-12 m-0">
                        <label htmlFor="company">Company Name</label>
                        <input pInputText id="company" />
                    </div>
                    <div class="field col-12 m-0">
                        <label htmlFor="email">Email<span class="text-red-500">*</span></label>
                        <input pInputText id="email" />
                        <input pInputText placeholder="Confirm Email" id="confirmemail"/>
                    </div>
        
                    <div class="field col-6 m-0">
                        <label htmlFor="address">Address</label>
                        <input pInputText id="city" rows="4" class="mb-0" />
                        <small class="pl-1 font-light text-color-secondary text-xs" htmlFor="city">City</small>
                    </div>
                    <div class="field col-6 mt-5 m-0">
                        <p-dropdown class="mb-0" optionLabel="state"></p-dropdown>
                        <small class="pl-1 font-light text-color-secondary text-xs" htmlFor="state">State/Region/Province</small>
                    </div>
                    <div class="field col-6 m-0">
                        <input pInputText id="state" type="text" class="mb-0"/>
                        <small class="pl-1 font-light text-color-secondary text-xs" htmlFor="state">Postal / Zip Code</small>
                    </div>
                    <div class="field col-6 m-0">
                        <p-dropdown optionLabel="country" class="mb-0"></p-dropdown>
                        <small class="pl-1 font-light text-color-secondary text-xs" htmlFor="country">Country</small>
                    </div>
                    <div class="field col-12">
                        <label htmlFor="address">Comment, question or concern<span class="text-red-500">*</span></label>
                        <textarea class="h-6rem" pInputTextarea id="address" rows="10"></textarea>
                    </div>
                    <div class="field ml-8 pl-5 sm:ml-0 sm:pl-0">
                        <button class="bg-button-sara border-transparent ml-2" pButton label="">Submit</button>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</p-dialog>

<p-dialog styleClass="w-full md:w-9 lg:w-10 xl:w-9" [(visible)]="privacyTerms">
    <pdf-viewer [src]="src_url" 
    [render-text]="true"
    [show-borders]="false"
    [fit-to-page]="true"
    [classList]="'w-full h-30rem'" 
    [original-size]="false">
    </pdf-viewer>
</p-dialog>





    
