import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {

  @Input() header: string | undefined
  @Input() subHeader: string | undefined
  @Input() buttonLabel: string = 'Learn More'
  @Input() buttonAction: string = ''
  
}
