import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { BaseLayoutComponent } from './common/components/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent extends BaseLayoutComponent {

  items: MenuItem[] = [];
  isLoggedIn: Observable<boolean> | undefined = of(false);
}