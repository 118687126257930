import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppLayoutComponent } from './app-layout.component';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from '../primeng.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    AppFooterComponent,
    AppLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PrimeNGModule,
    PdfViewerModule
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule { }
